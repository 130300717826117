import React from 'react'
import './Login.css'

function Login() {
  return (
    <div className='login-body'>
        <div class="container">
  <div class="card">
    <h2>Staff Login</h2>
    <form>
      <input type="text" id="username" name="username" placeholder="Username" required />
      <input type="password" id="password" name="password" placeholder="Password" required />
      <button className='login-btn' type="submit">Login</button>
    </form>
  </div>
</div>
    </div>
  )
}

export default Login