import logo from './logo.svg';
import './App.css';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './components/Home/Home'
import Login from './components/Login/Login';
import Pdf from './components/Pdf/Pdf';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login-staff" element={<Login />} />
          <Route path="/pdf" element={<Pdf />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
